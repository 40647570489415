

const UNITS = {
    B: v => v,
    KB: v => v * 1000,
    MB: v => v * 1000 * 1000,
    GB: v => v * 1000 * 1000 * 1000
}

/**
 * Fornece regras para validar ficheiro
 * @param options : { 
 *          maxFiles: number, // null -> unlimited
 *          file { // essa mesma regra é aplicada a cada ficheiro.
 *              size: { max: number, unit: 'B'|'KB'|'MB'|'GB' }, 
 *              accepts: [], https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
 *          },
 *          totalSize: { max: number, unit:  'B'|'KB'|'MB'|'GB' },
 *          errorMessages: [''],
 *          useInternalError: true // se é para gerar da função
 * }
 * 
 * -- examples
 * options = { totalSize: { max: 10, unit: 'MB' } }
 * vai acumular os tamanhos de todos os ficheiros, depois valida com esse 
 * options = { file : {size: { max: 10, unit: 'MB' } } }
 * vai validar cada ficheiro na lista de validação para ter essas specs
 */
export function factoryRule(options) {

    const { file: { size, accepts }, totalSize, errorMessages,  useInternalError, maxFiles} = Object.assign({
        file: { size: false, accepts: false },
        totalSize: false,
        maxFiles: null,
        errorMessages: [],
        useInternalError: true
    }, options)
    /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
    return function (files) {
        const errors = [];
        let errorFound = false;
        
        if (!files) return true;
        //console.warn(files);
        let nFiles;
        if (Array.isArray(files)) {
            nFiles = files;
        } else {
            nFiles = [files]; 
        }

        if (maxFiles !== null && maxFiles !== undefined ) {
            if (nFiles.length > maxFiles) {
                errorFound = true;
                const suffix = maxFiles > 1 ? 's' : null;
                useInternalError && errors.push(`${maxFiles} ficheiro${suffix} permitido${suffix}.`)
            }
        }
        let sizes = 0;

        nFiles.forEach(f => {
            //console.warn(f, sizes);
            if (totalSize) {
                sizes += f.size;
            }
            if (size) {
                const converter = UNITS[size.unit];
                if (f.size > converter(size.max)) {
                    errorFound = true;
                    useInternalError && errors.push(`${f.name} ultrapassou limite de ${size.max} ${size.unit}.`)
                }
            } 
            
            if (accepts) {
                if (accepts.includes(f.type)) {
                    errorFound = true;
                    useInternalError && errors.push(`'${f.type}' inválido em ${f.name}.`)
                }
            }
        });
        //console.warn(totalSize, sizes);
        if (totalSize) {
            const converter = UNITS[totalSize.unit];
            if (sizes > converter(totalSize.max)) {
                errorFound = true;
                useInternalError && errors.push(`ultrapassou limite  total de ${totalSize.max} ${totalSize.unit}.`)
            }
        }

        if (errorFound) {
            return [...errorMessages, ...errors].join('\n');
        }

        return true;
    }
}