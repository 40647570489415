<template>
    <v-card class="secondary">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-alert
                        color="#171717"
                        border="left"
                        colored-border
                        flat
                        class="body-2"
                        icon="mdi-calendar-heart"
                    >
                        <span v-if="item.id">Atulizar Pedido de Autorização.</span>
                        <span v-else>Registar novo  Pedido de Autorização.</span>
                    </v-alert>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12" md="12" sm="12">
                    <v-alert
                        v-for="(alert, i) in alerts" :key="i" 
                        dense text 
                        :type="`${ alert.success ? 'success': 'error' }`" 
                        dismissible>
                        {{ alert.message }}
                    </v-alert>
                </v-col>
            </v-row>

            <v-form
                ref="form"
                v-model="valid"
                :lazy-validation="lazy">

                <v-row>
                    <v-col>
                        <v-file-input
                            accept="application/pdf"
                            placeholder="Escolhe um ficheiro"
                            label="Ficheiro"
                            :rules="rules"
                            v-model="model.srcFile"
                            show-size
                            outlined
                            clearable
                            dense
                        ></v-file-input>
                    </v-col>
                </v-row>

                
                <v-row>
                    <v-col>
                        <v-btn 
                            :loading="processingRequest" :disabled="processingRequest" 
                            depressed color="primary" @click="add">
                            <span class="black--text">Submeter</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        
        </v-container>
    </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import * as file from '../../../../utils/file';
export default {
    props: {
        item: Object,
    },
    created() {
        this.model = _.cloneDeep(this.item);
    },
    data() {
        return {
            valid: true,
            lazy: false,
            model: {
                id: null,
                proof: null,
                srcFile: null,
            },
            alerts: [],
            rules: [
                v => {
                    if (!v  && !this.item.id) {
                        return 'Campo é de preenchimento obrigatório';
                    }
                    return true;
                },
                file.factoryRule({  totalSize: { max: 10, unit: 'MB' } }),
                //value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
            ],
            requiredRules: [
                v => !!v || 'Campo é de preenchimento obrigatório',
            ],
        }
    },
    computed: { 
        ...mapState('dashboard/festivalRequest',[ 'processingRequest' ]),
    },
    methods: {
        ...mapActions('dashboard/festivalRequest', ['createOrUpdate',]),

        add() {
            this.alerts = [];
            if (this.$refs.form.validate()) {

                this.createOrUpdate({data: this.model, token: this.$user.info}).then((data) => {
                    this.alerts = data.messages;

                    if (!this.item.id) {

                        if (data.success) {
                            this.model.id = null;
                            this.$refs.form.reset();
                        } else if (data.data) {
                            this.model.id = data.data.id
                        }
                        
                    }
                }).catch(() => {
                    this.alerts = [ {
                        success: false,
                        message: 'Problema no processamento'
                    }];
                });
                
            }
            
        },
    }
}
</script>