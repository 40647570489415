<template>
    <v-container>
        <Form :item="item"/>
    </v-container>
    
</template>
<script>
import Form from '../../../../components/dashboard/festival/request/Form';
import { HOME } from '../../../../routes';
export default {
    components: {
        Form
    },
    data() {
        return {
           
            item: {
                id: null,
                proof: null,
                srcFile: null,
                status: false,
                description: '',
            }
        }
    },
    created() {
        if (this.$user.isGuest) {
            this.$router.push({path: HOME}); //TODO check auth with globar guard
            return;
        }
    }
}
</script>